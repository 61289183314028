import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { v4 as uuid} from 'uuid'
import { Alert } from "react-bootstrap";
import DeleteConfirmation from './components/DeleteConfirmation'
import MyData from './myData.json'
import NavBar from './components/NavBar'
import Dashboard from './pages/Dashboard'
import Listing from './pages/Listing'
import Locations from './pages/Locations'
import Veggies from './pages/Veggies'

function MyVeggieGarden() {

  const [nightSwitch, setNightSwitch] = useState(false)
  const [locations, setLocations] = useState(MyData[0])
  const [veggies, setVeggies] = useState(MyData[1])
  const [plantations, setPlantations] = useState(MyData[2])
  let countLocations = 0
  locations.map(item => { if(item.allocated) { countLocations++} })
  const [totalLocations, setTotalLocations] = useState(countLocations)
  let countVeggies = 0
  veggies.map(item => { if(item.allocated) { countVeggies++} })
  const [totalVeggies, setTotalVeggies] = useState(countVeggies)
  let countPlantations = 0
  plantations.map(item => { if(item.allocated) { countPlantations++} })
  const [totalPlantations, setTotalPlantations] = useState(countPlantations)

  const [category, setCategory] = useState(null);
  const [allocated, setAllocated] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const deleteItem = (id, category, allocated) => {
    setCategory(category);
    setAllocated(allocated);
    setId(id);
    setAlertMessage(null);

    if (category === "location") {
      setDeleteMessage(`Are you sure you want to delete the location '${locations.find((x) => x.id === id).name}'?`);
    } else if (category === "veggie") {
      setDeleteMessage(`Are you sure you want to delete the veggie '${veggies.find((x) => x.id === id).name}'?`);
    }

    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = (type, id, allocated) => {
    console.log(allocated)
    if (type === "location") {
      setAlertMessage(`The location '${locations.find((x) => x.id === id).name}' was successfully deleted.`);
      setLocations(locations.filter((item) => item.id != id))
      if(allocated) { setTotalLocations(totalLocations - 1) }

      setAllocated(null);
      setId(null);
      setTimeout(function(){
        setAlertMessage(null);
      }, 3000); 
      
    } else if (type === "veggie") {
      setAlertMessage(`The veggie '${veggies.find((x) => x.id === id).name}' was successfully deleted.`);
      setVeggies(veggies.filter((item) => item.id != id))
      if(allocated) { setTotalVeggies(totalVeggies - 1) }

      setAllocated(null);
      setId(null);
      setTimeout(function(){
        setAlertMessage(null);
      }, 3000);
    }
    setDisplayConfirmationModal(false);
  };

  const addItem = (newItem, category) => {
    switch(category) {
      case 'location':
        newItem.id = uuid()
        newItem.allocated = false
        setLocations([newItem, ...locations])
        break
      case 'veggie':
        newItem.id = uuid()
        newItem.allocated = false
        setVeggies([newItem, ...veggies])
        break
    }
  }

  const nightMode = () => {
    if(nightSwitch) {
      const navBarClass = document.getElementsByClassName('navbar-dark')
      Array.from(navBarClass).forEach(e => {
        e.classList.remove('navbar-dark')
        e.classList.add('navbar-light')
      })
      const bgClass = document.getElementsByClassName('bg-dark')
      Array.from(bgClass).forEach(e => {
        e.classList.remove('bg-dark')
        e.classList.add('bg-light')
      })
      const textClass = document.getElementsByClassName('text-light')
      Array.from(textClass).forEach(e => {
        e.classList.remove('text-light')
        e.classList.add('text-dark')
      })
      const bgMainClass = document.getElementsByClassName('bg-main-dark')
      Array.from(bgMainClass).forEach(e => {
        e.classList.remove('bg-main-dark')
        e.classList.add('bg-main-light')
      })
      const iconClass = document.getElementsByClassName('icon-light')
      Array.from(iconClass).forEach(e => {
        e.classList.remove('icon-light')
        e.classList.add('icon-dark')
      })
      const navLinkClass = document.getElementsByClassName('nav-link-light')
      Array.from(navLinkClass).forEach(e => {
        e.classList.remove('nav-link-light')
        e.classList.add('nav-link-dark')
      })
      setNightSwitch(!nightSwitch)
    } else {
      const navBarClass = document.getElementsByClassName('navbar-light')
      Array.from(navBarClass).forEach(e => {
        e.classList.remove('navbar-light')
        e.classList.add('navbar-dark')
      })
      const bgClass = document.getElementsByClassName('bg-light')
      Array.from(bgClass).forEach(e => {
        e.classList.remove('bg-light')
        e.classList.add('bg-dark')
      })
      const textClass = document.getElementsByClassName('text-dark')
      Array.from(textClass).forEach(e => {
        e.classList.remove('text-dark')
        e.classList.add('text-light')
      })
      const bgMainClass = document.getElementsByClassName('bg-main-light')
      Array.from(bgMainClass).forEach(e => {
        e.classList.remove('bg-main-light')
        e.classList.add('bg-main-dark')
      })
      const iconClass = document.getElementsByClassName('icon-dark')
      Array.from(iconClass).forEach(e => {
        e.classList.remove('icon-dark')
        e.classList.add('icon-light')
      })
      const navLinkClass = document.getElementsByClassName('nav-link-dark')
      Array.from(navLinkClass).forEach(e => {
        e.classList.remove('nav-link-dark')
        e.classList.add('nav-link-light')
      })
      setNightSwitch(!nightSwitch)
    }
  }

  return (
    <>
      <div className="bg-main-light">
        <NavBar nightMode={ nightMode } nightSwitch={ nightSwitch } />
        <div className='container'>
          <Routes>
            <Route path='/' element={ <Dashboard locations={ totalLocations } veggies={ totalVeggies } plantations={ totalPlantations } /> } />
            <Route path='Listing' element={ <Listing locations={ locations } veggies={ veggies } plantations={ plantations } deleteItem={ deleteItem } /> } />
            <Route path='Locations' element={ <Locations data={ locations } deleteItem={ deleteItem } addItem={ addItem } /> } />
            <Route path='Veggies' element={ <Veggies data={ veggies } deleteItem={ deleteItem } addItem={ addItem } /> } />
          </Routes>
          <div className="row mt-5  d-flex justify-content-center">
            <div className='col-xl-8 col-md-10'>
              {alertMessage && <Alert variant="success">{alertMessage}</Alert>}
            </div>
          </div>
        </div>
        <DeleteConfirmation showModal={ displayConfirmationModal } confirmModal={ submitDelete } hideModal={ hideConfirmationModal } type={ category } id={ id } message={ deleteMessage } allocated={ allocated }  />
      </div>
    </>
  )
}

export default MyVeggieGarden