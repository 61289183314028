import React, { useState } from 'react'
import { FaSave } from "react-icons/fa"

function AddItem({ category, addItem }) {

  const [isDisabled, setIsDisabled] = useState(true)
  const [alertMessage, setAlertMessage] = useState('')
  const [text, setText] = useState('')

  const handleText = (e) => {
    if(e.target.value === '') {
      setIsDisabled(true)
      setAlertMessage('')
    } else if((e.target.value).trim().length <= 3) {
      setAlertMessage('Text must have more than 3 characters')
      setIsDisabled(true)
    } else {
      setAlertMessage('')
      setIsDisabled(false)
    }
    setText(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(text.trim().length > 3) {
      const newItem = {
        name: text
      }
      addItem(newItem, category)
      setText('')
    }
    e.target.reset()
  }

  return (
    <>
      <div className="col-xl-6 col-md-10 d-flex mb-2 d-flex justify-content-center">
        <form onSubmit={ handleSubmit }>
          <div className="input-group">
          <input onChange={ handleText } className='form-control float-start' type="text" />
          <button className='btn btn-light btn-sm ms-2 float-end' type='submit' disabled={ isDisabled }><FaSave /></button>
          </div>
        </form>
      </div>
      { alertMessage && <small className='text-center text-warning'>{ alertMessage }</small> }
    </>
  )
}

export default AddItem