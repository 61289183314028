import React, { useState } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { GrGrow } from "react-icons/gr";
import { FaMoon } from "react-icons/fa";

function NavBar({ nightMode, nightSwitch }) {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light navBarShadow">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <div className="d-flex align-items-center">
            <GrGrow className='fs-2 icon-dark' /> <span className='ms-2'>MyVeggieGarden</span>
            </div>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <CustomLink to="/Listing" nightSwitch={ nightSwitch }>Listing</CustomLink>
              <CustomLink to="/Locations" nightSwitch={ nightSwitch }>Locations</CustomLink>
              <CustomLink to="/Veggies" nightSwitch={ nightSwitch }>Veggies</CustomLink>
              <div className='d-flex align-items-center border me-2'></div>
              <div className="form-check form-switch d-flex align-items-center p-0">
                <FaMoon className='mt-1 me-5 icon-dark' />
                <input className="form-check-input" type="checkbox" onClick={ () => nightMode() }></input>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

function CustomLink({ to, children, nightSwitch, ...props}) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  let classes
  if(nightSwitch) {
    isActive ? classes = "nav-link nav-link-light nav-active" : classes = "nav-link nav-link-light"
  } else {
    isActive ? classes = "nav-link nav-link-dark nav-active" : classes = "nav-link nav-link-dark"
  }
  return (
    <li className='nav-item'>
      <Link className={ classes } to={ to } { ...props }>{ children }</Link>
    </li>
  )
}

export default NavBar