import React from 'react'
import ListItem from '../components/ListItem'
import AddItem from '../components/AddItem'

function Locations({ data, deleteItem, addItem }) {

  let content

  if(data.length != 0) {
    content = <table className="table table-secondary shadow">
      <tbody>
         { data.map(item => <ListItem key={ item.id } data={ item } category='location' deleteItem={ deleteItem } /> ) }
      </tbody>
    </table>
  } else {
    content = <p className='text-center pt-3 text-warning'>nothing to show</p>
  }

  return (
    <>
      <div className="row mt-5  d-flex justify-content-center">
        <div className='col-xl-8 col-md-10'>
            { content }
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-4">
        <AddItem category='location' addItem={ addItem } />
      </div>
    </>
  )
}

export default Locations