import React from 'react'
import { FaEdit } from "react-icons/fa"
import { AiFillDelete } from "react-icons/ai"

function ListItem({ data, category, deleteItem }) {
  return (
    <>
      <tr id={ data.id } className={ data.allocated ? 'table-success' : '' }>
        <td>{ data.name }</td>
        <td className='table-column-btn'>
          <button className='btn btn-light btn-sm'><FaEdit /></button>
        </td>
        <td className='table-column-btn'>
          <button className='btn btn-danger btn-sm' onClick={ () => deleteItem(data.id, category, data.allocated) }><AiFillDelete /></button>
        </td>
      </tr>
    </>
  )
}

export default ListItem