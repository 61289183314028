import React from 'react'
import { GrGrow, GrAppsRounded, GrTable } from "react-icons/gr";

function Dashboard({ locations, veggies, plantations }) {
  return (
    <>
      <div className="row mt-5  d-flex justify-content-evenly">
          <div className='col-10 col-sm-3 text-center bg-light text-dark border-start border-5 border-success shadow mb-4 mb-sm-0'>
          <GrTable className='fs-4 mt-3 mb-2 icon-dark' />
          <h3>Plantations</h3>
          <hr />
          <p className='fs-2'>{ plantations }</p>
          </div>
          <div className='col-10 col-sm-3 text-center bg-light text-dark border-start border-5 border-success shadow mb-4 mb-sm-0'>
            <GrAppsRounded className='fs-4 mt-3 mb-2 icon-dark' />
            <h3>Locations</h3>
            <hr />
            <p className='fs-2'>{ locations }</p>
          </div>
          <div className='col-10 col-sm-3 text-center bg-light text-dark border-start border-5 border-success shadow mb-4 mb-sm-0'>
          <GrGrow className='fs-4 mt-3 mb-2 icon-dark' />
          <h3>Veggies</h3>
          <hr />
          <p className='fs-2'>{ veggies }</p>
          </div>
      </div>
    </>
  )
}

export default Dashboard