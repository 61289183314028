import React from 'react'
import ListItem from '../components/ListItem'

function Listing({ locations, veggies, plantations, deleteItem }) {

  let content

  if(plantations.length != 0) {
    content = <table className="table table-secondary shadow">
      <tbody>
         { plantations.map(item => <ListItem key={ item.id } plantations={ item } category='location' deleteItem={ deleteItem } /> ) }
      </tbody>
    </table>
  } else {
    content = <p className='text-center pt-3 text-warning'>nothing to show</p>
  }

  return (
    <>
    <div className="row mt-5  d-flex justify-content-center">
      <div className='col-xl-8 col-md-10'>
        { content }
      </div>
    </div>
    <div className="row d-flex justify-content-center mt-4">
      <div className='col-xl-8 col-md-10'>
        <form className="row g-3">
          <div className="col-md-6">
            <label htmlFor="disabledSelect" className="form-label">Veggies</label>
            <select id="disabledSelect" className="form-select">
              <option>Empty</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="disabledSelect" className="form-label">Locations</label>
            <select id="disabledSelect" className="form-select">
              <option>Empty</option>
            </select>
          </div>
          <div className="col-md-4">
            <div className="form-check d-inline-block me-3">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"></input>
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Planted
              </label>
            </div>
            <div className="form-check d-inline-block">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"></input>
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Swon
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <label className="me-2">
              Planted Date
            </label>
            <input type="date" name="flexRadioDefault" id="flexRadioDefault1"></input>
          </div>
          <div className="col-md-4">
            <label className="me-2">
              Harvest Date
            </label>
            <input type="date" name="flexRadioDefault" id="flexRadioDefault1"></input>
          </div>
          <div className='d-flex justify-content-center'>
              <button type="submit" className="btn btn-success">Submit</button>
            </div>
        </form>
      </div>
    </div>
  </>
  )
}

export default Listing